import { useState } from 'react';
import {
  MultiLocationProgram,
  LocationConnection
} from 'src/generated/gql/graphql';
import classNames from 'classnames';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery, Box, Grid, Drawer } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';

import { contentSelectable } from 'src/common/blueprints';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import useBulkValidations from 'src/hooks/useBulkValidations';
import BulkAdPreview from 'src/components/AdPreview/BulkAdPreview';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from 'src/pages/Program/ProgramPreviewDrawer/constants';
import ProgramDynamicUserInputs from 'src/pages/Program/ProgramDynamicUserInputs';
import { getConditionalInputVisibilityFromBlueprint } from 'src/common/conditionals';

import ModalHeader from 'src/components/Modal/ModalHeader';
import Modal from 'src/components/Modal/Modal';

import { EDIT_PROGRAM_FORM_NAME } from 'src/pages/ProgramPerformance/Constants';

import ProgramName from 'src/pages/Program/ProgramName/ProgramName';
import { useStyles } from 'src/pages/Program/ProgramPreviewDrawer/ProgramPreviewDrawer';
import InnerContainer from 'src/pages/Program/components/InnerContainer';
import SentryUtil from 'src/common/SentryUtil';

import { getSelectedLocationsMetadata } from './utils/dataFormatters';
import { MlpEditSubmitData } from './ProgramEdit';
import FooterValidationErrors from './FooterValidationErrors';

type ProgramEditFormProps = {
  // facebook: any;
  multiLocationProgram?: Partial<MultiLocationProgram> | null | undefined;
  architecture: any;
  selectedBlueprint: any;
  selectedBusinessObjects: {
    selectedBusinessObjects: any[];
  };
  submitHandler: ({
    errors,
    includeOverrides
  }: MlpEditSubmitData) => (data: any) => Promise<void>;
  allLocations: LocationConnection;
  selectedLocations: (string | undefined)[];
};

const ProgramEditForm = (props: ProgramEditFormProps) => {
  const {
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    multiLocationProgram,
    submitHandler,
    allLocations,
    selectedLocations
  } = props;

  const globalContext = useGlobalContext();
  const formMethods = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [creativeValidationErrors, setCreativeValidationErrors] =
    useState<Record<string, any> | null>(null);

  const userMetadataFields = globalContext?.me?.metadata?.fields;

  const [overrideModalOpen, setOverrideModalOpen] = useState(false);

  const formValues = formMethods.watch();

  const submitForm = (config: MlpEditSubmitData) =>
    formMethods.handleSubmit(submitHandler(config))();

  const classes = useStyles({
    drawerPosition: 'relative'
  });

  const dynamicUserInputSections = selectedBlueprint.inputSections;
  const isContentSelectable = contentSelectable(
    architecture,
    selectedBlueprint
  );
  const contentColumns = contentColumnsFromArchitecture(architecture);
  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs: formValues?.dynamicUserInputs || {},
    businessObjects: selectedBusinessObjects?.selectedBusinessObjects || [],
    locationsOverrideById: {},
    selectedLocations
  };

  const [isPollingPreviewForAd, setIsPollingPreviewForAd] = useState(false);
  const [isLoadingAdForAd, setIsLoadingAdForAd] = useState(false);
  const [isValidatingCreativeForAd, setIsValidatingCreativeForAd] =
    useState(false);

  const [isPollingPreviewForValidation, setIsPollingPreviewForValidation] =
    useState(false);
  const [isLoadingAdForValidation, setIsLoadingAdForValidation] =
    useState(false);
  const [
    isValidatingCreativeForValidation,
    setIsValidatingCreativeForValidation
  ] = useState(false);

  const isLoadingAd =
    isPollingPreviewForAd || isValidatingCreativeForAd || isLoadingAdForAd;
  const isLoadingValidations =
    isPollingPreviewForValidation ||
    isLoadingAdForValidation ||
    isValidatingCreativeForValidation;

  const selectedLocationsMetadata = getSelectedLocationsMetadata(
    allLocations,
    selectedLocations as string[]
  );

  const conditionalInputsVisibility =
    getConditionalInputVisibilityFromBlueprint(
      selectedBlueprint,
      formValues,
      userMetadataFields,
      selectedBusinessObjects?.selectedBusinessObjects,
      selectedLocationsMetadata
    );

  const { pollingPreview, adPreviewError, executeSubmitValidations } =
    useBulkValidations({
      setIsPollingPreview: setIsPollingPreviewForValidation,
      setIsLoadingAd: setIsLoadingAdForValidation,
      isPollingPreviewExternal: isPollingPreviewForAd,
      conditionalInputsVisibility,
      architecture,
      setIsValidatingCreative: setIsValidatingCreativeForValidation,
      previewData,
      selectedLocation: selectedLocations[0],
      setCreativeValidationErrors
    });

  const isLoadingSomething =
    isLoadingAd || isLoadingValidations || pollingPreview;

  const LocationIdsWithOverrides = new Set(
    multiLocationProgram?.childOrders?.edges?.reduce<string[]>(
      (overrides, edge) => {
        if (
          edge?.node?.multiLocationChildProgramDetails
            ?.variableValuesAreOverridden
        ) {
          return [
            ...overrides,
            edge?.node?.multiLocationChildProgramDetails?.locationId
          ];
        }
        return overrides;
      },
      []
    ) || []
  );

  const locationsWithOverrides =
    allLocations?.edges
      ?.filter(
        location =>
          location?.node?.id && LocationIdsWithOverrides.has(location?.node?.id)
      )
      .map(location => location?.node) || [];

  const theme: Theme = useTheme();
  const previewDrawerOpen = useMediaQuery(
    theme.breakpoints.up(DRAWER_FULL_SCREEN_BREAKPOINT)
  );

  const launchErrorSnackbar = (e: any) => {
    SentryUtil.captureException(e);
    enqueueSnackbar(t('programPerf:editMlp.errorValidating'), {
      variant: 'error'
    });
  };

  const contentName =
    architecture?.catalog?.friendlyName ||
    t('programCreate:configure.contentDefaultName');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingTop: 3
        }}
        data-cy="topy"
      >
        <InnerContainer
          previewDrawerOpen={previewDrawerOpen}
          data-cy="innercontainer"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
            data-cy="isnnie"
          >
            <form style={{ width: '100%' }}>
              <ProgramName
                contentColumns={contentColumns}
                businessObjects={previewData.businessObjects}
                displayNameTemplate={displayNameTemplate}
                selectedBlueprint={selectedBlueprint}
                label={t('programCreate:programNameInput.labelMlp')}
                isHookForm
                formValues={formValues}
              />

              <ProgramDynamicUserInputs
                blueprint={selectedBlueprint}
                dynamicUserInputSections={dynamicUserInputSections}
                contentColumns={contentColumns}
                businessObjects={previewData.businessObjects}
                formName={EDIT_PROGRAM_FORM_NAME}
                isContentSelectable={isContentSelectable}
                isHookForm
                isMultiLocation
                hookFormMethods={formMethods}
                selectedLocationsMetadata={selectedLocationsMetadata}
              />
              <Grid>
                <Grid item>
                  <FooterValidationErrors
                    channelValidationErrors={creativeValidationErrors?.defaults}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="primary"
                    loading={isLoadingSomething}
                    disabled={
                      isLoadingSomething ||
                      !!adPreviewError ||
                      creativeValidationErrors?.defaults
                    }
                    data-cy="step-footer-checkout"
                    id="step-footer-next-button"
                    onClick={() => {
                      if (locationsWithOverrides.length > 0) {
                        setOverrideModalOpen(true);
                      } else {
                        executeSubmitValidations()
                          .then(errors => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            submitForm({ errors, includeOverrides: false });
                          })
                          .catch(e => {
                            launchErrorSnackbar(e);
                          });
                      }
                    }}
                  >
                    {t('programPerf:editMlp.updatePrograms')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>

          <Drawer
            // adding this key here forces the drawer to re-render when the blueprint changes
            // so adpreview etc aren't in stale states
            key={selectedBlueprint?.blueprintId || 'noBlueprint'}
            anchor="right"
            variant="permanent"
            open={previewDrawerOpen}
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: previewDrawerOpen,
              [classes.drawerClose]: !previewDrawerOpen
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: previewDrawerOpen,
                [classes.drawerClose]: !previewDrawerOpen,
                [classes.drawerPaper]: true
              })
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Box
              sx={theme => ({
                pr: 8,
                pl: 0,
                pb: 4,
                pt: 0,
                overflowY: 'scroll',
                width: '100%',
                position: 'relative',
                [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
                  pr: 0,
                  pt: 3,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '100vh'
                },

                [theme.breakpoints.between(
                  0,
                  theme.evSizes.previewDrawerWidth
                )]: {
                  px: 2,
                  margin: 0
                }
              })}
            >
              {/* This extra box is needed to ensure that the vertical overflow scroll bar is positioned to the far right of the screen */}
              <Box
                sx={theme => ({
                  [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
                    maxWidth: theme.evSizes.previewDrawerWidth,
                    margin: '0 auto',
                    width: '100%'
                  }
                })}
              >
                <BulkAdPreview
                  architecture={architecture}
                  contentName={contentName}
                  previewData={previewData}
                  showLoadingSkeleton
                  disableResponsiveStyles
                  displayAsPhoneMockUp
                  isResponsive
                  selectedLocation={null}
                  setIsPollingPreview={setIsPollingPreviewForAd}
                  isPollingPreview={isPollingPreviewForAd}
                  setIsValidatingCreative={setIsValidatingCreativeForAd}
                  clearUpdatedInputCreativeErrors
                  setIsLoadingAd={setIsLoadingAdForAd}
                  setCreativeValidationErrors={setCreativeValidationErrors}
                  conditionalInputsVisibility={conditionalInputsVisibility}
                />
              </Box>
            </Box>
          </Drawer>
        </InnerContainer>
      </Box>
      <Modal
        open={overrideModalOpen}
        showClose={false}
        onClose={() => {
          setOverrideModalOpen(false);
        }}
        HeaderComponent={
          <ModalHeader icon={<SaveIcon />}>
            <Box component="span" sx={{ fontSize: '20px' }}>
              {t('programPerf:editMlp.confirmHeader')}
            </Box>
          </ModalHeader>
        }
        FooterComponent={
          <>
            <LoadingButton
              onClick={() => {
                setOverrideModalOpen(false);
              }}
              loading={isLoadingSomething}
              disabled={isLoadingSomething}
            >
              {t('programStepFooter:overrideModal.cancel')}
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              onClick={() => {
                executeSubmitValidations()
                  .then(errors => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    submitForm({ errors, includeOverrides: true });
                  })
                  .catch(e => {
                    launchErrorSnackbar(e);
                  });
              }}
              loading={isLoadingSomething}
            >
              {t('programStepFooter:overrideModal.applyAll')}
            </LoadingButton>

            <LoadingButton
              autoFocus
              variant="contained"
              onClick={() => {
                executeSubmitValidations()
                  .then(errors => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    submitForm({ errors, includeOverrides: false });
                  })
                  .catch(e => {
                    launchErrorSnackbar(e);
                  });
              }}
              loading={isLoadingSomething}
            >
              {t('programStepFooter:overrideModal.applyUnmodified')}
            </LoadingButton>
          </>
        }
      >
        <>
          {isLoadingSomething ? (
            <Box sx={{ padding: 2, textAlign: 'center' }}>
              Saving Changes...
            </Box>
          ) : (
            <>
              {t('programStepFooter:overrideModal.content', {
                count: locationsWithOverrides.length
              })}
              <ul>
                {locationsWithOverrides?.map(location => (
                  <li>
                    {location?.name} - {location?.city}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default ProgramEditForm;
